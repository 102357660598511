@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.background-image{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

code {
  font-family: 'Poppins', sans-serif;
}

.MuiTypography-root, .MuiButtonBase-root{
  font-family: unset !important;
}